export default {
    acceptTermsOfUse: 'Elfogad',
    unavailable: 'Nem elérhető',
    loading: 'Töltés folyamatban',
    password: 'Jelszó',
    email: 'Email cím',
    username: 'Felhasználónév',
    id: 'Azonosító',
    rememberMe: 'Emlékezzen rám',
    logoutInProgress: 'Kijelentkezés folyamatban.',
    organizationUnit: 'Szervezeti egység',
    filingId: 'Iktatószám',
    procurementSubject: 'Beszerzés tárgya',
    bmlId: 'BML',
    economyDirectorateFilingId: 'GI iktatószám',
    filteringSubmit: 'Szűrés',
    filteringSave: 'Szűrők állapotának mentése',
    filteringReset: 'Szűrési feltételek törlése',
    procurements: 'Kérelmek',
    notifyEmail: 'Folyamat előrehaladásáról értesítendő személy email címe',
    save: 'Mentés',
    modify: 'Rögzít',
    requesterUser: {
        name: 'Kérelmező neve',
        email: 'Kérelmező email címe',
        position: 'Kérelmező beosztása',
        filingCode: 'Kérelem szervezeti iktatószáma',
        phone: 'Kérelmező telefonszáma',
        vatCode: 'Kérelmező adóazonosító jele'
    },
    authorUser: {
        name: 'Kitöltő neve',
        email: 'Kitöltő email címe',
    },
    eventTitle: 'Konferencia elnevezése',
    eventMissionGoal: 'Kiküldetés célja',
    cardFrames: {
        eventDetails: 'Konferencia, rendezvény adatok',
        organizationAndPersonal: 'Szervezeti és személyi adatok rögzítése',
        expectedCosts: 'A kiküldetés során várhatóan felmerülő költségek',
        vehicleInfo: 'Gépjármű adatok',
        finance: 'Pénzügyi adatok',
        fuelSubsidy: 'Gépjármű dokumentumai',
        accommodation: 'Szállás',
        registrationFee: 'Regisztrációs adatok',
        coordination: 'Koordinációs adatok',
        comments: 'Megjegyzések'
    },
    isForeign: 'Külföldi rendezvény',
    unfilled: 'Nincs kitöltve',
    requiresFuelSubsidy: 'Üzemanyag hozzájárulás igénylése',
    requiresAccommodationSubsidy: 'Szállásdíj hozzájárulás igénylése',
    requiresRegistrationFee: 'Regisztrációs-díj hozzájárulás igénylése',
    country: 'Kiküldetési ország',
    location: 'Helyszín (város)',
    isPresenter: 'Részvétel módja',
    presenter: 'Előadó',
    participant: 'Résztvevő',
    reasoning: 'A kiküldetés szakmai indoklása',
    programOfConference: 'Konferencia program',
    otherAttachment: 'Egyéb dokumentum',
    travelByPlane: 'Repülő',
    travelByCar: 'Autó',
    travelByBus: 'Busz',
    travelByTrain: 'Vonat',
    travelByShip: 'Hajó',
    travelMode: 'Utazás módja',
    missionStart: 'Kiküldetés kezdete',
    missionEnd: 'Kiküldetés vége',
    yes: 'Igen',
    no: 'Nem',
    invitationLetter: 'Meghívólevél',
    costType: 'Költség típus',
    grossSum: 'Bruttó összeg',
    editExpenditure: 'Új tétel felvétele',
    modals: {
        editExpenditure: {
            title: 'Tételrögzítő',
            type: 'Költségtípus',
            price: 'Várható bruttó összeg'
        },

        removeExpenditure: {
            title: 'Biztosan törölni akarja?'
        }
    },
    vehicleRegistrationCertificate: 'Forgalmi engedély két oldalának felöltése',
    vehicleInsurance: 'Kötelező gépjármű felelősségbiztosítás felöltése',
    vehicleCasco: 'Casco kötvény feltöltése',
    vehicleLeasing: 'Kölcsönbeadási szerződés felöltése',
    registrationFeeDeadline: 'Regisztráció befizetés határideje',
    billingCompanyData: 'Regisztrációt számlázó cég neve és elérhetősége',
    registrationFeeIncludesAccommodation: 'Regisztrációs díj tartalmazza a szállást',
    registrationFeeIncludesBreakfast: 'Regisztrációs díj tartalmazza a reggelit',
    accommodation: {
        city: 'Város',
        name: 'Szálláshely neve',
        billingCompany: 'Számlázó cég neve, elérhetősége',
        arriveDate: 'Szálláshelyre érkezés napja',
        departureDate: 'Szálláshelyről távozás napja',
    },
    substituteUser: 'Helyettesítő felhasználó',
    userIsBanned: 'Kitiltva',
    roles: {
        'System.Administrator': 'Rendszer adminisztrátor',
        Requester: 'Igénylő',
        ProcurementCoordinator: 'Beszerzési Koordinátor',
        Countersignator: 'Ellenjegyző',
        LiabilityProvider: 'Kötelezettségvállaló',
        Finance: 'Pénzügy',
    },
    superiorUser: 'Szervezeti vezető',
    expenditureTypes: {
        registration: 'Regisztrációs díj',
        onlineConference: 'Online konferencia díja',
        daily: 'Napi díj',
        accommodation: 'Szállásdíj',
        travelInsurance: 'Utasbiztosítás',
        visa: 'Vízum',
        train: 'Vonatjegy',
        bus: 'Buszjegy',
        ship: 'Hajó jegy',
        airplane: 'Repülőjegy',
        taxi: 'Taxi vagy transzfer díja',
        car: 'Gépjármű',
        other: 'Egyéb',
    }
};
